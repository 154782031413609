import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/project/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Link, Props } from 'docz';
import { GridContainer, GridItem } from '@entur/grid';
import { ChoiceChip, ChoiceChipGroup } from '@entur/chip';
import { TextField } from '@entur/form';
import { PrimaryButton } from '@entur/button';
import PageHeader from '~/components/PageHeader';
import { Playground } from '~/components/playground/Playground';
import { ImportStatement } from '~/components/ImportStatement';
import './grid.scss';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader" />
    <Playground __position={1} __code={'() => {\n  const [value, setValue] = React.useState(\'extraSmall\')\n  return (\n    <>\n      <GridContainer spacing={value}>\n        <GridItem small={12} className=\"grid-demo-item\">\n          small=12\n        </GridItem>\n        <GridItem small={6} className=\"grid-demo-item\">\n          small=6\n        </GridItem>\n        <GridItem small={6} className=\"grid-demo-item\">\n          small=6\n        </GridItem>\n        <GridItem small={3} className=\"grid-demo-item\">\n          small=3\n        </GridItem>\n        <GridItem small={3} className=\"grid-demo-item\">\n          small=3\n        </GridItem>\n        <GridItem small={3} className=\"grid-demo-item\">\n          small=3\n        </GridItem>\n        <GridItem small={3} className=\"grid-demo-item\">\n          small=3\n        </GridItem>\n      </GridContainer>\n      <ChoiceChipGroup\n        name=\"spacing\"\n        label=\"Spacing\"\n        onChange={e => setValue(e.target.value)}\n        value={value}\n        style={{ marginTop: \'1.5rem\' }}\n      >\n        <ChoiceChip value=\"none\">none</ChoiceChip>\n        <ChoiceChip value=\"extraSmall2\">extraSmall2</ChoiceChip>\n        <ChoiceChip value=\"extraSmall\">extraSmall</ChoiceChip>\n        <ChoiceChip value=\"small\">small</ChoiceChip>\n        <ChoiceChip value=\"medium\">medium</ChoiceChip>\n        <ChoiceChip value=\"large\">large</ChoiceChip>\n        <ChoiceChip value=\"extraLarge\">extraLarge</ChoiceChip>\n      </ChoiceChipGroup>\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Link,
      Props,
      GridContainer,
      GridItem,
      ChoiceChip,
      ChoiceChipGroup,
      TextField,
      PrimaryButton,
      PageHeader,
      Playground,
      ImportStatement,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [value, setValue] = React.useState('extraSmall');
        return <>
        <GridContainer spacing={value} mdxType="GridContainer">
          <GridItem small={12} className="grid-demo-item" mdxType="GridItem">
            small=12
          </GridItem>
          <GridItem small={6} className="grid-demo-item" mdxType="GridItem">
            small=6
          </GridItem>
          <GridItem small={6} className="grid-demo-item" mdxType="GridItem">
            small=6
          </GridItem>
          <GridItem small={3} className="grid-demo-item" mdxType="GridItem">
            small=3
          </GridItem>
          <GridItem small={3} className="grid-demo-item" mdxType="GridItem">
            small=3
          </GridItem>
          <GridItem small={3} className="grid-demo-item" mdxType="GridItem">
            small=3
          </GridItem>
          <GridItem small={3} className="grid-demo-item" mdxType="GridItem">
            small=3
          </GridItem>
        </GridContainer>
        <ChoiceChipGroup name="spacing" label="Spacing" onChange={e => setValue(e.target.value)} value={value} style={{
            marginTop: '1.5rem'
          }} mdxType="ChoiceChipGroup">
          <ChoiceChip value="none" mdxType="ChoiceChip">none</ChoiceChip>
          <ChoiceChip value="extraSmall2" mdxType="ChoiceChip">extraSmall2</ChoiceChip>
          <ChoiceChip value="extraSmall" mdxType="ChoiceChip">extraSmall</ChoiceChip>
          <ChoiceChip value="small" mdxType="ChoiceChip">small</ChoiceChip>
          <ChoiceChip value="medium" mdxType="ChoiceChip">medium</ChoiceChip>
          <ChoiceChip value="large" mdxType="ChoiceChip">large</ChoiceChip>
          <ChoiceChip value="extraLarge" mdxType="ChoiceChip">extraLarge</ChoiceChip>
        </ChoiceChipGroup>
      </>;
      }}
    </Playground>
    <h2 {...{
      "id": "komponenter"
    }}>{`Komponenter`}</h2>
    <h3 {...{
      "id": "gridcontainer"
    }}>{`GridContainer`}</h3>
    <ImportStatement imports="GridContainer" mdxType="ImportStatement" />
    <Props of={GridContainer} mdxType="Props" />
    <h3 {...{
      "id": "griditem"
    }}>{`GridItem`}</h3>
    <ImportStatement imports="GridItem" mdxType="ImportStatement" />
    <Props of={GridItem} mdxType="Props" />
    <p>{`Vår grid går etter prinsippet om 12 kolonner. Hver GridContainer har 12 kolonner, og hver GridItem tar opp så mange kolonner man skulle ønske.`}</p>
    <p>{`Grid baserer seg på tre forskjellige breakpoints: small, medium og large, som utledes fra breakpoints-tokens i.e. 800px og 1200px. Disse fungerer dra fra og med en skjermbredde og oppover. Det vil si, hvis ingen large eller medium verdi er satt, så ser Grid etter hvilken small verdi som er satt.
Så om du ikke skal bruke de responsive funksjonene til Grid, benytt `}<inlineCode parentName="p">{`small`}</inlineCode>{` når du setter størrelser på GridItem'er.`}</p>
    <p>{`Navngivningen til spacing-verdiene følger samme konvensjon som spacing-tokens.
Det vil si f.eks. extraSmall2 for xxs (tskjorte)`}</p>
    <h2 {...{
      "id": "eksempler"
    }}>{`Eksempler`}</h2>
    <h3 {...{
      "id": "responsivitet"
    }}>{`Responsivitet`}</h3>
    <p>{`Endre bredden på browser-vinduet for å teste responsiviteten.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Small`}</strong>{` er vinduer mellom 0-800 piksler i bredden.`}</li>
      <li parentName="ul"><strong parentName="li">{`Medium`}</strong>{` er vinduer mellom 800-1200 piksler.`}</li>
      <li parentName="ul"><strong parentName="li">{`Large`}</strong>{` er vinduer over 1200 piksler.`}</li>
    </ul>
    <Playground __position={4} __code={'<GridContainer spacing=\"medium\">\n  <GridItem small={12} medium={6} large={4} className=\"grid-demo-item\">\n    small=12 medium=6 large=4\n  </GridItem>\n  <GridItem small={12} medium={6} large={4} className=\"grid-demo-item\">\n    small=12 medium=6 large=4\n  </GridItem>\n  <GridItem small={6} medium={3} large={4} className=\"grid-demo-item\">\n    small=6 medium=3 large=4\n  </GridItem>\n  <GridItem small={6} medium={3} large={2} className=\"grid-demo-item\">\n    small=6 medium=3 large=2\n  </GridItem>\n  <GridItem small={4} medium={3} large={2} className=\"grid-demo-item\">\n    small=4 medium=3 large=2\n  </GridItem>\n  <GridItem small={4} medium={3} large={2} className=\"grid-demo-item\">\n    small=4 medium=3 large=2\n  </GridItem>\n</GridContainer>'} __scope={{
      props,
      DefaultLayout,
      Link,
      Props,
      GridContainer,
      GridItem,
      ChoiceChip,
      ChoiceChipGroup,
      TextField,
      PrimaryButton,
      PageHeader,
      Playground,
      ImportStatement,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <GridContainer spacing="medium" mdxType="GridContainer">
    <GridItem small={12} medium={6} large={4} className="grid-demo-item" mdxType="GridItem">
      small=12 medium=6 large=4
    </GridItem>
    <GridItem small={12} medium={6} large={4} className="grid-demo-item" mdxType="GridItem">
      small=12 medium=6 large=4
    </GridItem>
    <GridItem small={6} medium={3} large={4} className="grid-demo-item" mdxType="GridItem">
      small=6 medium=3 large=4
    </GridItem>
    <GridItem small={6} medium={3} large={2} className="grid-demo-item" mdxType="GridItem">
      small=6 medium=3 large=2
    </GridItem>
    <GridItem small={4} medium={3} large={2} className="grid-demo-item" mdxType="GridItem">
      small=4 medium=3 large=2
    </GridItem>
    <GridItem small={4} medium={3} large={2} className="grid-demo-item" mdxType="GridItem">
      small=4 medium=3 large=2
    </GridItem>
  </GridContainer>
    </Playground>
    <h3 {...{
      "id": "forskjellig-spacing-på-kolonner-og-rader"
    }}>{`Forskjellig spacing på kolonner og rader`}</h3>
    <Playground __position={5} __code={'<GridContainer spacing=\"extraSmall\" rowSpacing=\"large\">\n  <GridItem small={12} className=\"grid-demo-item\">\n    small=12\n  </GridItem>\n  <GridItem small={6} className=\"grid-demo-item\">\n    small=6\n  </GridItem>\n  <GridItem small={6} className=\"grid-demo-item\">\n    small=6\n  </GridItem>\n  <GridItem small={3} className=\"grid-demo-item\">\n    small=3\n  </GridItem>\n  <GridItem small={3} className=\"grid-demo-item\">\n    small=3\n  </GridItem>\n  <GridItem small={3} className=\"grid-demo-item\">\n    small=3\n  </GridItem>\n  <GridItem small={3} className=\"grid-demo-item\">\n    small=3\n  </GridItem>\n</GridContainer>'} __scope={{
      props,
      DefaultLayout,
      Link,
      Props,
      GridContainer,
      GridItem,
      ChoiceChip,
      ChoiceChipGroup,
      TextField,
      PrimaryButton,
      PageHeader,
      Playground,
      ImportStatement,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <GridContainer spacing="extraSmall" rowSpacing="large" mdxType="GridContainer">
    <GridItem small={12} className="grid-demo-item" mdxType="GridItem">
      small=12
    </GridItem>
    <GridItem small={6} className="grid-demo-item" mdxType="GridItem">
      small=6
    </GridItem>
    <GridItem small={6} className="grid-demo-item" mdxType="GridItem">
      small=6
    </GridItem>
    <GridItem small={3} className="grid-demo-item" mdxType="GridItem">
      small=3
    </GridItem>
    <GridItem small={3} className="grid-demo-item" mdxType="GridItem">
      small=3
    </GridItem>
    <GridItem small={3} className="grid-demo-item" mdxType="GridItem">
      small=3
    </GridItem>
    <GridItem small={3} className="grid-demo-item" mdxType="GridItem">
      small=3
    </GridItem>
  </GridContainer>
    </Playground>
    <h3 {...{
      "id": "eksempel-med-komponenter"
    }}>{`Eksempel med komponenter`}</h3>
    <Playground __position={6} __code={'() => {\n  const [value, setValue] = React.useState(undefined)\n  return (\n    <GridContainer spacing=\"medium\" rowSpacing=\"large\">\n      <GridItem small={6}>\n        <TextField label=\"Fra\" />\n      </GridItem>\n      <GridItem small={6}>\n        <TextField label=\"Til\" />\n      </GridItem>\n      <GridItem small={12}>\n        <ChoiceChipGroup\n          name=\"tickettype\"\n          label=\"Billettype\"\n          onChange={e => setValue(e.target.value)}\n          value={value}\n        >\n          <ChoiceChip value=\"barn\">Barn</ChoiceChip>\n          <ChoiceChip value=\"voksen\">Voksen</ChoiceChip>\n          <ChoiceChip value=\"honnør\">Honnør</ChoiceChip>\n          <ChoiceChip value=\"millitær\">Millitær</ChoiceChip>\n        </ChoiceChipGroup>\n      </GridItem>\n      <GridItem small={3}>\n        <PrimaryButton width=\"fluid\">Kjøp</PrimaryButton>\n      </GridItem>\n    </GridContainer>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Link,
      Props,
      GridContainer,
      GridItem,
      ChoiceChip,
      ChoiceChipGroup,
      TextField,
      PrimaryButton,
      PageHeader,
      Playground,
      ImportStatement,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [value, setValue] = React.useState(undefined);
        return <GridContainer spacing="medium" rowSpacing="large" mdxType="GridContainer">
        <GridItem small={6} mdxType="GridItem">
          <TextField label="Fra" mdxType="TextField" />
        </GridItem>
        <GridItem small={6} mdxType="GridItem">
          <TextField label="Til" mdxType="TextField" />
        </GridItem>
        <GridItem small={12} mdxType="GridItem">
          <ChoiceChipGroup name="tickettype" label="Billettype" onChange={e => setValue(e.target.value)} value={value} mdxType="ChoiceChipGroup">
            <ChoiceChip value="barn" mdxType="ChoiceChip">Barn</ChoiceChip>
            <ChoiceChip value="voksen" mdxType="ChoiceChip">Voksen</ChoiceChip>
            <ChoiceChip value="honnør" mdxType="ChoiceChip">Honnør</ChoiceChip>
            <ChoiceChip value="millitær" mdxType="ChoiceChip">Millitær</ChoiceChip>
          </ChoiceChipGroup>
        </GridItem>
        <GridItem small={3} mdxType="GridItem">
          <PrimaryButton width="fluid" mdxType="PrimaryButton">Kjøp</PrimaryButton>
        </GridItem>
      </GridContainer>;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      